import { IDashboardUser } from "entities/IDashboardUser";
import { storeLoginData } from "./session";

export const validateCredentials = async (
  user: IDashboardUser
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const envUserName = process.env.REACT_APP_USER;
    const envPass = process.env.REACT_APP_PASS;

    setTimeout(() => {
      if (user.userName === envUserName && user.passKey === envPass) {
        storeLoginData(user);
        resolve(true);
      } else {
        reject('Credenciales Incorrectas');
      }
    }, 300);
  });
};
