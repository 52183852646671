import axios, { AxiosRequestConfig } from "axios";
import { IPartner } from "entities/IPartner";

const baseURL: string = process.env.REACT_APP_API_URL ?? "";

const reqConfig: AxiosRequestConfig = {
  baseURL,
};

export const getPartners = async (): Promise<IPartner[]> => {
  const resp = await axios.get<IPartner[]>("/partners", {
    ...reqConfig,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return resp.data;
};

export const postPartner = async (req: FormData) => {
  const resp = await axios.post("/partners", req, reqConfig);
  return resp.data;
};

export const putPartner = async (partnerID: string, req: FormData) => {
  const resp = await axios.put(`/partners/${partnerID}`, req, reqConfig);
  return resp.data;
};

export const deletePartner = async (partnerID: string): Promise<any> => {
  const resp = await axios.delete(`/partners/${partnerID}`, reqConfig);
  return resp.data;
};
