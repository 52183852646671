import React, { useEffect } from "react";
import App from "components/app/app.component";
import AdminLayout from "components/layouts/admin.layout";
import { Outlet } from "react-router-dom";

export default function Root() {
  return (
    <AdminLayout>
      <Outlet />
    </AdminLayout>
  );
}
