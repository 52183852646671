import { IDashboardUser } from 'entities/IDashboardUser';

export const getLoginData = (): IDashboardUser | null => {
  const loginInfoJewelrock = sessionStorage.getItem('loginInfoJewelrock');

  if (!loginInfoJewelrock) return null;

  const user: IDashboardUser = JSON.parse(loginInfoJewelrock);
  return user;
};

export const storeLoginData = (user: IDashboardUser) => {
  sessionStorage.setItem('loginInfoJewelrock', JSON.stringify(user));
};

export const clearLoginData = () => {
  sessionStorage.removeItem('loginInfoJewelrock');
};
