export const colors = {
  primaryWhite: "#ffffff",
  opacityWhite: "#ffffff26",
  opacityWhite2: "#ffffff81",
  opacityBlack: "rgba(0, 0, 0, 0.418)",
  primaryGrey: "#e0e0e0a4",
  primaryGreen: "#4ac49d",
  opacityGreen: "#4ac49d6b",
  primaryBlue: "#000828",
  opacityBlue: "#0008286b",
  secondaryBlue: "#031f4f",
  error: "#ff4f4f",
  neutral: "#bbcacc",
  danger: "#d86464",
};
