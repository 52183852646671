import axios, { AxiosRequestConfig } from "axios";
import { IProgram } from "entities/IProgram";

const baseURL: string = process.env.REACT_APP_API_URL ?? "";

const reqConfig: AxiosRequestConfig = {
  baseURL,
};

export const getPrograms = async (): Promise<IProgram[]> => {
  const resp = await axios.get<IProgram[]>("/programs", {
    ...reqConfig,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return resp.data;
};

export const postProgram = async (req: FormData) => {
  const resp = await axios.post("/programs", req, reqConfig);
  return resp.data;
};

export const putProgram = async (programID: string, req: FormData) => {
  const resp = await axios.put(`/programs/${programID}`, req, reqConfig);
  return resp.data;
};

export const deleteProgram = async (programID: string): Promise<any> => {
  const resp = await axios.delete(`/programs/${programID}`, reqConfig);
  return resp.data;
};

export const uploadPdf = async (req: FormData) => {
  const resp = await axios.post("/programpdf", req, reqConfig);
  return resp.data;
};

