import { colors } from "constants/colors";
import styled from "styled-components";

export const LoginContainer = styled.div`
  background-color: ${colors.primaryBlue};
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const LogoContainer = styled.img`
  width: 30%;
  min-width: 300px;
  margin-bottom: 30px;
`;

export const FormContainer = styled.form`
  width: 35%;
  min-width: 400px;
  text-align: center;
  border-radius: 25px;
  background-color: ${colors.primaryWhite};
  padding-bottom: 3vw;
`;

export const LoginTitle = styled.h1``;

export const LoginInput = styled.input`
  display: block;
  margin-left: auto;
  margin-right: auto;
  outline: none;
  width: 80%;
  border: 2px solid ${colors.secondaryBlue};
  border-radius: 7px;
  font-size: 1.5em;
  padding: 0.5em 1em;
  margin-bottom: 1vw;
`;

export const LoginButton = styled.button`
  background-color: ${colors.primaryGreen};
  display: block;
  margin-left: auto;
  margin-right: auto;
  outline: none;
  width: 80%;
  font-size: 1.5em;
  padding: 0.5em 1em;
  border-color: ${colors.primaryGreen};
  border-radius: 7px;
  cursor: pointer;
  font-weight: 600;
  color: ${colors.primaryBlue}
`;
