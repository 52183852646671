import axios, { AxiosRequestConfig } from "axios";
import { ISpeaker } from "entities/ISpeaker";

const baseURL: string = process.env.REACT_APP_API_URL ?? "";

const reqConfig: AxiosRequestConfig = {
  baseURL,
};

export const getSpeakers = async (): Promise<ISpeaker[]> => {
  const resp = await axios.get<ISpeaker[]>("/speakers", {
    ...reqConfig,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return resp.data;
};

export const postSpeaker = async (req: FormData) => {
  const resp = await axios.post("/speakers", req, reqConfig);
  return resp.data;
};

export const putSpeaker = async (speakerID: string, req: FormData) => {
  const resp = await axios.put(`/speakers/${speakerID}`, req, reqConfig);
  return resp.data;
};

export const deleteSpeaker = async (speakerID: string): Promise<any> => {
  const resp = await axios.delete(`/speakers/${speakerID}`, reqConfig);
  return resp.data;
};
