import React, { useState, useCallback, useEffect, ChangeEvent } from "react";
import {
  Container,
  HeaderContainer,
  TableContainer,
  Table,
  TableHeader,
  RowDescription,
  PageTitle,
  NewButton,
  FormContainer,
  FormTitle,
  FormInput,
  FormInputFile,
  FormArea,
  FormImage,
  LabelInput,
  FormLabelContainer,
  FormButton,
  LoaderContainer,
  FormLoaderContainer,
  NotFoundLabel,
} from "./speakers.styles";
import { ISpeaker } from "entities/ISpeaker";
import {
  deleteSpeaker,
  getSpeakers,
  postSpeaker,
  putSpeaker,
} from "services/speakers";
import ModalComponent from "components/modal/modal.component";
import { useForm, SubmitHandler } from "react-hook-form";
import { userIcon } from "constants/images";
import Swal from "sweetalert2";
import { colors } from "constants/colors";
import { Grid, ThreeCircles } from "react-loader-spinner";

export default function SpeakersPage() {
  const [speakersList, setSpeakersList] = useState<ISpeaker[]>([]);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [actualSpeaker, setActualSpeaker] = useState<ISpeaker | null>();
  const [loader, setLoader] = useState<boolean>(false);

  const getAllSpeakers = useCallback(async () => {
    try {
      setLoader(true);
      // console.log(loader)
      let speakers = await getSpeakers();
      setLoader(false);
      setSpeakersList(speakers);
    } catch (error) {
      console.error("SERVER ERROR");
    }
  }, []);

  useEffect(() => {
    getAllSpeakers();
  }, []);

  return (
    <>
      <Container>
        <HeaderContainer>
          <PageTitle>Speakers Console</PageTitle>
          <NewButton
            onClick={() => {
              setActualSpeaker(null);
              setVisibleModal(true);
            }}
          >
            New +
          </NewButton>
        </HeaderContainer>
        <LoaderContainer visible={loader}>
          <Grid
            height="80"
            width="80"
            color={colors.primaryBlue}
            ariaLabel="grid-loading"
            radius="12.5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={loader}
          />
        </LoaderContainer>
        <NotFoundLabel visible={!loader && !speakersList.length}>
          Not found Speakers
        </NotFoundLabel>
        <TableContainer visible={!loader && speakersList.length > 0}>
          <Table>
            <thead>
              <TableHeader>
                <th>Name</th>
                <th>Title</th>
                <th>Company</th>
                <th>Description</th>
              </TableHeader>
            </thead>
            <tbody>
              {speakersList.map((speaker: ISpeaker, index: number) => (
                <SpeakerRow
                  key={"speaker-row-" + index}
                  speaker={speaker}
                  onClick={() => {
                    setActualSpeaker(speaker);
                    setVisibleModal(true);
                  }}
                />
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </Container>
      <SpeakerModal
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
        actualSpeaker={actualSpeaker}
        getAllSpeakers={getAllSpeakers}
      />
    </>
  );
}

type RowProps = {
  speaker: ISpeaker;
  onClick?: React.MouseEventHandler<HTMLTableRowElement>;
};

const SpeakerRow = ({ speaker, onClick }: RowProps) => {
  return (
    <RowDescription onClick={onClick}>
      <td>{speaker.speakerName}</td>
      <td>{speaker.title}</td>
      <td>{speaker.company}</td>
      <td>{speaker.description}</td>
    </RowDescription>
  );
};

type SpeakerModalProps = {
  visibleModal: boolean;
  setVisibleModal: React.Dispatch<React.SetStateAction<boolean>>;
  actualSpeaker?: ISpeaker | null;
  getAllSpeakers: () => Promise<void>;
};

function SpeakerModal({
  visibleModal,
  setVisibleModal,
  actualSpeaker,
  getAllSpeakers,
}: SpeakerModalProps) {
  const [actualImg, setActualImg] = useState<string>(userIcon);
  const [loader, setLoader] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<ISpeaker>();

  const onSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (e!.target!.files!.length > 0) {
      let urlAux = URL.createObjectURL(e!.target!.files![0]!);

      setActualImg(urlAux);
    }
  };

  const onSubmit: SubmitHandler<ISpeaker> = async (mesageInfo) => {
    try {
      setLoader(true);
      let formData = new FormData();
      formData.append("speakerName", mesageInfo!.speakerName!);
      formData.append("title", mesageInfo!.title!);
      formData.append("company", mesageInfo!.company!);
      formData.append(
        "description",
        mesageInfo!.description!.replaceAll("\n", "<br/>")
      );
      if (mesageInfo!.img!.length > 0) {
        formData.append("file", mesageInfo!.img![0]);
      } else if (actualSpeaker) {
        formData.append("img", actualSpeaker!.img!);
      }

      if (actualSpeaker) await putSpeaker(actualSpeaker.id!, formData);
      else await postSpeaker(formData);
      setLoader(false);
      getAllSpeakers();
      setVisibleModal(false);
    } catch (error) {}
  };

  const onDelete = async () => {
    Swal.fire({
      title: "Are you sure about deleting an speaker?",
      background: colors.secondaryBlue,
      iconColor: colors.primaryGreen,
      color: colors.primaryWhite,
      confirmButtonColor: colors.primaryGreen,
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        try {
          setLoader(true);
          await deleteSpeaker(actualSpeaker!.id!);
          setLoader(false);
          getAllSpeakers();
          setVisibleModal(false);
          Swal.fire("Delete!", "", "success");
        } catch (error) {}
      }
    });
  };

  useEffect(() => {
    if (actualSpeaker) {
      reset();
      setValue("speakerName", actualSpeaker.speakerName, {
        shouldValidate: true,
      });
      setValue("title", actualSpeaker.title, { shouldValidate: true });
      setValue("company", actualSpeaker.company, { shouldValidate: true });
      setValue(
        "description",
        actualSpeaker.description!.replaceAll("<br></br>", "\n").replaceAll("<br/>", "\n"),
        {
          shouldValidate: true,
        }
      );
      setActualImg(actualSpeaker.img!);
    } else {
      setActualImg(userIcon);
      reset();
    }
  }, [actualSpeaker]);

  return (
    <ModalComponent
      show={visibleModal}
      onClose={() => {
        setVisibleModal(false);
      }}
    >
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <FormLoaderContainer visible={loader}>
          <ThreeCircles
            height="100"
            width="100"
            color={colors.primaryGreen}
            wrapperStyle={{}}
            wrapperClass=""
            visible={loader}
            ariaLabel="three-circles-rotating"
          />
        </FormLoaderContainer>
        <FormTitle>
          {actualSpeaker ? (
            <>
              Update Speaker{" "}
              <span
                className="icon-trash"
                onClick={() => {
                  onDelete();
                }}
              ></span>
            </>
          ) : (
            "Create Speaker"
          )}
        </FormTitle>
        <FormImage src={actualImg} />
        <FormInputFile
          type="file"
          placeholder="Image"
          {...register("img", {
            onChange: (e) => {
              onSelect(e);
            },
            required: false,
          })}
        />
        <FormLabelContainer>
          <LabelInput size="50%">
            Name
            <FormInput
              type="text"
              placeholder="Name"
              {...register("speakerName", {
                required: true,
              })}
            />
          </LabelInput>
          <LabelInput size="50%">
            Company
            <FormInput
              type="text"
              placeholder="Company"
              {...register("company", {
                required: true,
              })}
            />
          </LabelInput>
          <LabelInput>
            Title
            <FormInput
              type="text"
              placeholder="Title"
              {...register("title", {
                required: true,
              })}
            />
          </LabelInput>
          <LabelInput>
            Description
            <FormArea
              rows={4}
              placeholder="Description"
              {...register("description", {
                required: true,
              })}
            />
          </LabelInput>
        </FormLabelContainer>
        <FormButton>Submit</FormButton>
      </FormContainer>
    </ModalComponent>
  );
}
