import { colors } from "constants/colors";
import styled from "styled-components";

export const AppContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 30px;
`;
export const ItemMain = styled.div`
  margin: 20px;
  background-color: ${colors.primaryGreen};
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: ${colors.primaryGrey};

    & div {
      color: ${colors.primaryGreen};
    }
  }
`;
export const ItemIcon = styled.div`
  color: ${colors.secondaryBlue};
  font-weight: bold;
  font-size: 3em;
  margin-bottom: 0.3em;
`;

export const ItemName = styled.div`
  color: ${colors.secondaryBlue};
  font-size: 1.8em;
`;
