import React, { useState, useCallback, useEffect, ChangeEvent } from "react";
import {
  Container,
  HeaderContainer,
  TableContainer,
  Table,
  TableHeader,
  RowDescription,
  PageTitle,
  NewButton,
  FormContainer,
  FormTitle,
  FormInput,
  FormInputFile,
  FormArea,
  FormImage,
  LabelInput,
  FormLabelContainer,
  FormButton,
  LoaderContainer,
  FormLoaderContainer,
  NotFoundLabel,
} from "./exhibitors.styles";
import { IExhibitor } from "entities/IExhibitor";
import {
  deleteExhibitor,
  getExhibitors,
  postExhibitor,
  putExhibitor,
} from "services/exhibitors";
import ModalComponent from "components/modal/modal.component";
import { useForm, SubmitHandler } from "react-hook-form";
import { partnerIcon } from "constants/images";
import Swal from "sweetalert2";
import { colors } from "constants/colors";
import { Grid, ThreeCircles } from "react-loader-spinner";

export default function ExhibitorsPage() {
  const [exhibitorsList, setExhibitorsList] = useState<IExhibitor[]>([]);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [actualExhibitor, setActualExhibitor] = useState<IExhibitor | null>();
  const [loader, setLoader] = useState<boolean>(false);

  const getAllExhibitors = useCallback(async () => {
    try {
      setLoader(true);
      // console.log(loader)
      let exhibitors = await getExhibitors();
      setLoader(false);
      setExhibitorsList(exhibitors);
    } catch (error) {
      console.error("SERVER ERROR");
    }
  }, []);

  useEffect(() => {
    getAllExhibitors();
  }, []);

  return (
    <>
      <Container>
        <HeaderContainer>
          <PageTitle>Exhibitors Console</PageTitle>
          <NewButton
            onClick={() => {
              setActualExhibitor(null);
              setVisibleModal(true);
            }}
          >
            New +
          </NewButton>
        </HeaderContainer>
        <LoaderContainer visible={loader}>
          <Grid
            height="80"
            width="80"
            color={colors.primaryBlue}
            ariaLabel="grid-loading"
            radius="12.5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={loader}
          />
        </LoaderContainer>
        <NotFoundLabel visible={!loader && !exhibitorsList.length}>
          No found Exhibitors
        </NotFoundLabel>
        <TableContainer visible={!loader && exhibitorsList.length > 0}>
          <Table>
            <thead>
              <TableHeader>
                <th>Exhibitor</th>
                <th>Description</th>
                <th>Link</th>
                <th>exType</th>
              </TableHeader>
            </thead>
            <tbody>
              {exhibitorsList.map((exhibitor: IExhibitor, index: number) => (
                <ExhibitorRow
                  key={"exhibitor-row-" + index}
                  exhibitor={exhibitor}
                  onClick={() => {
                    setActualExhibitor(exhibitor);
                    setVisibleModal(true);
                  }}
                />
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </Container>
      <ExhibitorModal
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
        actualExhibitor={actualExhibitor}
        getAllExhibitors={getAllExhibitors}
      />
    </>
  );
}

type RowProps = {
  exhibitor: IExhibitor;
  onClick?: React.MouseEventHandler<HTMLTableRowElement>;
};

const ExhibitorRow = ({ exhibitor, onClick }: RowProps) => {
  return (
    <RowDescription onClick={onClick}>
      <td>{exhibitor.exhibitor}</td>
      <td>{exhibitor.description}</td>
      <td>{exhibitor.link}</td>
      <td>{exhibitor.exType}</td>
    </RowDescription>
  );
};

type ExhibitorModalProps = {
  visibleModal: boolean;
  setVisibleModal: React.Dispatch<React.SetStateAction<boolean>>;
  actualExhibitor?: IExhibitor | null;
  getAllExhibitors: () => Promise<void>;
};

function ExhibitorModal({
  visibleModal,
  setVisibleModal,
  actualExhibitor,
  getAllExhibitors,
}: ExhibitorModalProps) {
  const [actualImg, setActualImg] = useState<string>(partnerIcon);
  const [loader, setLoader] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IExhibitor>();

  const onSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (e!.target!.files!.length > 0) {
      let urlAux = URL.createObjectURL(e!.target!.files![0]!);

      setActualImg(urlAux);
    }
  };

  const onSubmit: SubmitHandler<IExhibitor> = async (mesageInfo) => {
    try {
      setLoader(true);
      let formData = new FormData();
      formData.append("exhibitor", mesageInfo!.exhibitor!);
      formData.append("description", mesageInfo!.description!);
      formData.append("link", mesageInfo!.link!);
      formData.append("exType", mesageInfo!.exType!);
      if (mesageInfo!.img!.length > 0) {
        formData.append("file", mesageInfo!.img![0]);
      } else if (actualExhibitor) {
        formData.append("img", actualExhibitor!.img!);
      }

      if (actualExhibitor) await putExhibitor(actualExhibitor.id!, formData);
      else await postExhibitor(formData);
      setLoader(false);
      getAllExhibitors();
      setVisibleModal(false);
    } catch (error) {}
  };

  const onDelete = async () => {
    Swal.fire({
      title: "Are you sure about deleting an Exhibitor?",
      background: colors.secondaryBlue,
      iconColor: colors.primaryGreen,
      color: colors.primaryWhite,
      confirmButtonColor: colors.primaryGreen,
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        try {
          setLoader(true);
          await deleteExhibitor(actualExhibitor!.id!);
          setLoader(false);
          getAllExhibitors();
          setVisibleModal(false);
          Swal.fire("Delete!", "", "success");
        } catch (error) {}
      }
    });
  };

  useEffect(() => {
    if (actualExhibitor) {
      reset();
      setValue("exhibitor", actualExhibitor.exhibitor, { shouldValidate: true });
      setValue("description", actualExhibitor.description, { shouldValidate: true });
      setValue("link", actualExhibitor.link!, { shouldValidate: true });
      setValue("exType", actualExhibitor.exType, { shouldValidate: true });
      setActualImg(actualExhibitor.img!);
    } else {
      setActualImg(partnerIcon);
      reset();
    }
  }, [actualExhibitor, visibleModal]);

  return (
    <ModalComponent
      show={visibleModal}
      onClose={() => {
        setVisibleModal(false);
      }}
    >
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <FormLoaderContainer visible={loader}>
          <ThreeCircles
            height="100"
            width="100"
            color={colors.primaryGreen}
            wrapperStyle={{}}
            wrapperClass=""
            visible={loader}
            ariaLabel="three-circles-rotating"
          />
        </FormLoaderContainer>
        <FormTitle>
          {actualExhibitor ? (
            <>
              Update Exhibitor{" "}
              <span
                className="icon-trash"
                onClick={() => {
                  onDelete();
                }}
              ></span>
            </>
          ) : (
            "Create Exhibitor"
          )}
        </FormTitle>
        <FormImage src={actualImg} />
        <FormInputFile
          type="file"
          placeholder="Image"
          {...register("img", {
            onChange: (e) => {
              onSelect(e);
            },
            required: false,
          })}
        />
        <FormLabelContainer>
          <LabelInput size="100%">
            Exhibitor - Dimentions ( 1200 x 625 px)
            <FormInput
              type="text"
              placeholder="Exhibitor"
              {...register("exhibitor", {
                required: true,
              })}
            />
          </LabelInput>
          <LabelInput>
            Description
            <FormArea
              rows={4}
              placeholder="Description"
              {...register("description", {
                required: true,
              })}
            />
          </LabelInput>
          <LabelInput>
            Url
            <FormInput
              type="text"
              placeholder="Url"
              {...register("link", {
                required: true,
              })}
            />
          </LabelInput>
          <LabelInput>
            Exhibitor Type
            <FormInput
              type="text"
              placeholder="Tech or Startup"
              {...register("exType", {
                required: true,
              })}
            />
          </LabelInput>
        </FormLabelContainer>
        <FormButton>Submit</FormButton>
      </FormContainer>
    </ModalComponent>
  );
}