import { colors } from "constants/colors";
import styled from "styled-components";

export const MainDiv = styled.div``;

export const Header = styled.header`
  padding: 20px 50px;
  background-color: ${colors.primaryBlue};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PageTitle = styled.span`
  font-size: 2em;
  color: ${colors.primaryWhite};
`;

export const LogoImg = styled.img`
  cursor: pointer;
  width: 200px;
`;

export const LogOutIcon = styled.span`
  color: ${colors.primaryWhite};
  font-size: 2em;
  cursor: pointer;

  &: hover {
    color: ${colors.primaryGreen};
  }
`;
