import { redirect } from "react-router-dom";
import { validateCredentials } from "services/login";
import { getLoginData } from "services/session";

export async function loaderRequiresAuth(exclude: boolean, redirectTo: string) {
  const onLoadData = getLoginData();
  try {
    if (onLoadData) {
      const loginState = await validateCredentials(onLoadData);

      if (loginState && exclude) {
        return redirect(redirectTo);
      }
    } else throw "error";
  } catch (error) {
    if (!exclude) return redirect(redirectTo);
  }

  return null;
}
