import axios, { AxiosRequestConfig } from "axios";
import { IAdvisor } from "entities/IAdvisor";

const baseURL: string = process.env.REACT_APP_API_URL ?? "";

const reqConfig: AxiosRequestConfig = {
  baseURL,
};

export const getAdvisors = async (): Promise<IAdvisor[]> => {
  const resp = await axios.get<IAdvisor[]>("/advistory", {
    ...reqConfig,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return resp.data;
};

export const postAdvisor = async (req: FormData) => {
  const resp = await axios.post("/advistory", req, reqConfig);
  return resp.data;
};

export const putAdvisor = async (advisorID: string, req: FormData) => {
  const resp = await axios.put(`/advistory/${advisorID}`, req, reqConfig);
  return resp.data;
};

export const deleteAdvisor = async (advisorID: string): Promise<any> => {
  const resp = await axios.delete(`/advistory/${advisorID}`, reqConfig);
  return resp.data;
};
