import styled from "styled-components";
import { colors } from "constants/colors";

interface Props {
  opacity?: number;
}

export const ModalBackground = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  inset: 0px;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.443);
  transition: opacity 250ms ease-in 0s;

  z-index: 100 !important;

  opacity: ${(props) => props.opacity};

  @media (max-width: 420px) {
    padding: 0px;
  }
`;

export const ModalCloseArea = styled.div<Props>`
  background-color: transparent;
  position: absolute;
  inset: 0px;
`;

export const ModalContainer = styled.div<Props>`
  margin: 0px;
  background-color: ${colors.primaryWhite};
  padding: 10px 20px;
  border-radius: 6px;
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
  z-index: 2;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 420px) {
    padding: 5vw;
    max-height: unset;
    border-radius: 0px;
    height: 100vh;
  }
`;
