import React, { ReactNode, useEffect, useState } from "react";
import {
  Header,
  MainDiv,
  LogoImg,
  LogOutIcon,
  PageTitle,
} from "./admin.styles";
import { logo } from "constants/images";
import { clearLoginData } from "services/session";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  page?: string;
  children?: ReactNode;
};

export default function AdminLayout({ children, page }: Props) {
  const location = useLocation();
  const navigate = useNavigate();

  const [pageTitle, setPageTitle] = useState<string>("");

  function convertRoute(route: string): string {
    let nameRoute = route.split("/")[1];
    return nameRoute.charAt(0).toUpperCase() + nameRoute.slice(1);
  };

  useEffect(() => {
    setPageTitle(`${convertRoute(location.pathname)}`);
  }, [location]);

  return (
    <MainDiv>
      <Header>
        <LogoImg
          src={logo}
          onClick={() => {
            navigate("/");
          }}
        />
        {/* <PageTitle>{pageTitle}</PageTitle> */}
        <LogOutIcon
          className="icon-log-out"
          onClick={() => {
            clearLoginData();
            navigate("/login");
          }}
        ></LogOutIcon>
      </Header>
      {children!}
    </MainDiv>
  );
}
