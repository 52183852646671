import React, { useState, useCallback, useEffect, ChangeEvent } from "react";
import {
  Container,
  HeaderContainer,
  TableContainer,
  Table,
  TableHeader,
  RowDescription,
  PageTitle,
  NewButton,
  FormContainer,
  FormTitle,
  FormInput,
  FormInputFile,
  FormArea,
  FormImage,
  LabelInput,
  FormLabelContainer,
  FormButton,
  LoaderContainer,
  FormLoaderContainer,
  NotFoundLabel,
  DynamicListConatiner,
  DynamicListHeader,
  DynamicListTitle,
  DynamicListAddRemove,
  DynamicListContent,
  DynamicListItem,
  FormSelect,
  PDFContainer,
  DownloadPDF,
} from "./programs.styles";
import { IProgram } from "entities/IProgram";
import {
  deleteProgram,
  getPrograms,
  postProgram,
  putProgram,
  uploadPdf,
} from "services/programs";
import ModalComponent from "components/modal/modal.component";
import { useForm, SubmitHandler } from "react-hook-form";
import Swal from "sweetalert2";
import { colors } from "constants/colors";
import { Grid, ThreeCircles } from "react-loader-spinner";
import { getAdvisors } from "services/advisors";
import { getPartners } from "services/partners";
import { getSpeakers } from "services/speakers";

export default function ProgramsPage() {
  const [programsList, setProgramsList] = useState<IProgram[]>([]);
  const [advisorsList, setAdvisorsList] = useState<{ [x: string]: string }>({});
  const [partnersList, setPartnersList] = useState<{ [x: string]: string }>({});

  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [actualProgram, setActualProgram] = useState<IProgram | null>();
  const [loader, setLoader] = useState<boolean>(false);

  const getAllList = useCallback(async () => {
    try {
      let advisors = await getSpeakers();
      let partners = await getPartners();
      let objAdvisor: { [x: string]: string } = {};
      advisors.forEach((advisor) => {
        objAdvisor[advisor!.id! as string] = advisor.speakerName as string;
      });

      let objPartner: { [x: string]: string } = {};
      partners.forEach((partner) => {
        objPartner[partner!.id! as string] = partner.partner as string;
      });

      setAdvisorsList(objAdvisor);
      setPartnersList(objPartner);
    } catch (error) {
      console.error("SERVER ERROR");
    }
  }, []);

  const getAllPrograms = useCallback(async () => {
    try {
      setLoader(true);
      getAllList();
      let programs = await getPrograms();
      setLoader(false);
      setProgramsList(programs);
    } catch (error) {
      console.error("SERVER ERROR");
    }
  }, []);

  useEffect(() => {
    getAllPrograms();
  }, []);

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e!.target!.files!.length > 0) {
      let urlAux = URL.createObjectURL(e!.target!.files![0]!);

      let formData = new FormData();
      formData.append("file", e!.target!.files![0]!);
      await uploadPdf(formData);
    }
  };

  return (
    <>
      <Container>
        <HeaderContainer>
          <PageTitle>Programs Console</PageTitle>
          <NewButton
            onClick={() => {
              setActualProgram(null);
              setVisibleModal(true);
            }}
          >
            New +
          </NewButton>
        </HeaderContainer>
        <PDFContainer>
          Upload Program PDF
          <div>
            <DownloadPDF href="https://thefair-images.s3.ap-southeast-2.amazonaws.com/pdf/programthefair.pdf" download="The Fair Program" target="_blank">
              <span className="icon-download"></span>
            </DownloadPDF>
            <FormInputFile
              type="file"
              placeholder="Upload PDF"
              onChange={handleChange}
            />
          </div>
        </PDFContainer>
        <LoaderContainer visible={loader}>
          <Grid
            height="80"
            width="80"
            color={colors.primaryBlue}
            ariaLabel="grid-loading"
            radius="12.5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={loader}
          />
        </LoaderContainer>
        <NotFoundLabel visible={!loader && !programsList.length}>
          Not found Programs
        </NotFoundLabel>
        <TableContainer visible={!loader && programsList.length > 0}>
          <Table>
            <thead>
              <TableHeader>
                <th>Group</th>
                <th>Title</th>
                <th>Day</th>
                <th>Hour</th>
                <th>Room</th>
              </TableHeader>
            </thead>
            <tbody>
              {programsList.map((program: IProgram, index: number) => (
                <ProgramRow
                  key={"program-row-" + index}
                  program={program}
                  onClick={() => {
                    setActualProgram(program);
                    setVisibleModal(true);
                  }}
                />
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </Container>
      <ProgramModal
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
        actualProgram={actualProgram}
        getAllPrograms={getAllPrograms}
        partnersList={partnersList}
        speakersList={advisorsList}
      />
    </>
  );
}

type RowProps = {
  program: IProgram;
  onClick?: React.MouseEventHandler<HTMLTableRowElement>;
};

const ProgramRow = ({ program, onClick }: RowProps) => {
  return (
    <RowDescription onClick={onClick}>
      <td>{program.programGroup}</td>
      <td>{program.programTitle}</td>
      <td>{program.programDay}</td>
      <td>{program.programHour}</td>
      <td>{program.programRoom}</td>
    </RowDescription>
  );
};

type ProgramModalProps = {
  visibleModal: boolean;
  setVisibleModal: React.Dispatch<React.SetStateAction<boolean>>;
  actualProgram?: IProgram | null;
  getAllPrograms: () => Promise<void>;
  partnersList: { [x: string]: string };
  speakersList: { [x: string]: string };
};

function ProgramModal({
  visibleModal,
  setVisibleModal,
  actualProgram,
  getAllPrograms,
  partnersList,
  speakersList,
}: ProgramModalProps) {
  const [speakersProgram, setSpeakersProgram] = useState<string[]>([]);
  const [partnersProgram, setPartnersProgram] = useState<string[]>([]);

  const [loader, setLoader] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IProgram>();

  const onSubmit: SubmitHandler<IProgram> = async (mesageInfo) => {
    try {
      setLoader(true);
      let formData = new FormData();
      formData.append("programDay", `${mesageInfo!.programDay!}`);
      formData.append("programGroup", mesageInfo!.programGroup!);
      formData.append("programTitle", mesageInfo!.programTitle!);
      formData.append("programHour", mesageInfo!.programHour!);
      formData.append("programRoom", mesageInfo!.programRoom!);
      formData.append(
        "description",
        // mesageInfo!.description!.replaceAll("\n", "<br></br>")
        mesageInfo!.description!.replaceAll("\n", "<br/>")
      );
      formData.append("speakers", JSON.stringify(speakersProgram));
      formData.append("partners", JSON.stringify(partnersProgram));

      if (actualProgram) await putProgram(actualProgram.id!, formData);
      else await postProgram(formData);
      setLoader(false);
      getAllPrograms();
      setVisibleModal(false);
    } catch (error) {}
  };

  const onDelete = async () => {
    Swal.fire({
      title: "Are you sure about deleting an program?",
      background: colors.secondaryBlue,
      iconColor: colors.primaryGreen,
      color: colors.primaryWhite,
      confirmButtonColor: colors.primaryGreen,
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        try {
          setLoader(true);
          await deleteProgram(actualProgram!.id!);
          setLoader(false);
          getAllPrograms();
          setVisibleModal(false);
          Swal.fire("Delete!", "", "success");
        } catch (error) {}
      }
    });
  };

  useEffect(() => {
    if (actualProgram) {
      reset();
      setValue("programDay", actualProgram.programDay, {
        shouldValidate: true,
      });
      setValue("programGroup", actualProgram.programGroup, {
        shouldValidate: true,
      });
      setValue("programTitle", actualProgram.programTitle, {
        shouldValidate: true,
      });
      setValue("programHour", actualProgram.programHour, {
        shouldValidate: true,
      });
      setValue("programRoom", actualProgram.programRoom, {
        shouldValidate: true,
      });
      setValue(
        "description",
        actualProgram
          .description!.replaceAll("<br></br>", "\n")
          .replaceAll("<br/>", "\n"),
        {
          shouldValidate: true,
        }
      );
      setSpeakersProgram(actualProgram.speakers!);
      setPartnersProgram(actualProgram.partners!);
    } else {
      setSpeakersProgram([]);
      setPartnersProgram([]);
      reset();
    }
  }, [actualProgram, visibleModal]);

  return (
    <ModalComponent
      show={visibleModal}
      onClose={() => {
        setVisibleModal(false);
      }}
    >
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <FormLoaderContainer visible={loader}>
          <ThreeCircles
            height="100"
            width="100"
            color={colors.primaryGreen}
            wrapperStyle={{}}
            wrapperClass=""
            visible={loader}
            ariaLabel="three-circles-rotating"
          />
        </FormLoaderContainer>
        <FormTitle>
          {actualProgram ? (
            <>
              Update Program{" "}
              <span
                className="icon-trash"
                onClick={() => {
                  onDelete();
                }}
              ></span>
            </>
          ) : (
            "Create Program"
          )}
        </FormTitle>
        <FormLabelContainer>
          <LabelInput size="40%">
            Title
            <FormInput
              type="text"
              placeholder="Title"
              {...register("programTitle", {
                required: true,
              })}
            />
          </LabelInput>
          <LabelInput size="40%">
            Room
            <FormInput
              type="text"
              placeholder="Room"
              {...register("programRoom", {
                required: true,
              })}
            />
          </LabelInput>
          <LabelInput size="20%">
            Day
            <FormInput
              type="number"
              min="1"
              max="2"
              placeholder="Day"
              {...register("programDay", {
                required: true,
              })}
            />
          </LabelInput>
          <LabelInput size="50%">
            Group
            <FormSelect
              defaultValue=""
              {...register("programGroup", {
                required: true,
              })}
            >
              <option value="none" selected disabled hidden>
                Select Group
              </option>
              <option value="The FAIR Stage">The FAIR Stage</option>
              <option value="Executive Roundtables">
                Executive Roundtables
              </option>
              <option value="Interactive Workshops">
                Interactive Workshops and Forums
              </option>
              <option value="Demonstrations">Demonstrations</option>
              <option value="The FAIR Book Lounge">The FAIR Book Lounge</option>
            </FormSelect>
          </LabelInput>
          <LabelInput size="50%">
            Hour
            <FormInput
              type="time"
              placeholder="Hour"
              {...register("programHour", {
                required: true,
              })}
            />
          </LabelInput>
          <LabelInput>
            Description
            <FormArea
              rows={4}
              placeholder="Description"
              {...register("description", {
                required: true,
              })}
            />
          </LabelInput>
          <PartnersList
            list={partnersList}
            name="partners"
            size="50%"
            showList={partnersProgram}
            setShowList={setPartnersProgram}
          />
          <PartnersList
            list={speakersList}
            name="speakers"
            size="50%"
            showList={speakersProgram}
            setShowList={setSpeakersProgram}
          />
        </FormLabelContainer>
        <FormButton>Submit</FormButton>
      </FormContainer>
    </ModalComponent>
  );
}

type PartnersListProps = {
  size?: string;
  name: "partners" | "speakers";
  list: { [x: string]: string };
  showList: string[];
  setShowList: React.Dispatch<React.SetStateAction<string[]>>;
};

const PartnersList = ({
  list,
  name,
  size,
  showList,
  setShowList,
}: PartnersListProps) => {
  useEffect(() => {}, []);

  const handlerAdd = () => {
    Swal.fire({
      confirmButtonColor: colors.primaryGreen,
      cancelButtonColor: "red",
      title: "Select Partner",
      input: "select",
      inputOptions: list,
      showCancelButton: true,
      confirmButtonText: "Save",
    }).then((result) => {
      if (result.isConfirmed) {
        let auxArray = [...showList];
        auxArray.push(result.value);
        setShowList(auxArray);
      } else if (result.isDenied) {
      }
    });
  };

  const handlerRemove = () => {
    let auxArray = [...showList];
    auxArray.pop();
    setShowList(auxArray);
  };

  return (
    <DynamicListConatiner size={size}>
      <DynamicListHeader>
        <DynamicListTitle>
          {name == "partners" ? "Partners" : "Speakers"}
        </DynamicListTitle>
        <DynamicListAddRemove
          color="green"
          onClick={() => {
            handlerAdd();
          }}
        >
          +
        </DynamicListAddRemove>
        <DynamicListAddRemove
          color="red"
          onClick={() => {
            handlerRemove();
          }}
        >
          -
        </DynamicListAddRemove>
      </DynamicListHeader>
      <DynamicListContent>
        {showList.map((elem: string, index: number) => (
          <DynamicListItem key={elem}>
            <span>-</span> {list[elem]}
          </DynamicListItem>
        ))}
      </DynamicListContent>
    </DynamicListConatiner>
  );
};
