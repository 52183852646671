import axios, { AxiosRequestConfig } from "axios";
import { IHotel } from "entities/IHotel";

const baseURL: string = process.env.REACT_APP_API_URL ?? "";

const reqConfig: AxiosRequestConfig = {
  baseURL,
};

export const getHotels = async (): Promise<IHotel[]> => {
  const resp = await axios.get<IHotel[]>("/hotels", {
    ...reqConfig,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return resp.data;
};

export const postHotel = async (req: FormData) => {
  const resp = await axios.post("/hotels", req, reqConfig);
  return resp.data;
};

export const putHotel = async (hotelID: string, req: FormData) => {
  const resp = await axios.put(`/hotels/${hotelID}`, req, reqConfig);
  return resp.data;
};

export const deleteHotel = async (hotelID: string): Promise<any> => {
  const resp = await axios.delete(`/hotels/${hotelID}`, reqConfig);
  return resp.data;
};
