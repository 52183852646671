import AdminLayout from "components/layouts/admin.layout";
import { ISections } from "entities/ISections";
import React from "react";
import { AppContainer, ItemIcon, ItemName, ItemMain } from "./app.styles";
import { useNavigate } from "react-router-dom";

const sectionList: ISections[] = [
  {
    icon: "icon-coin-dollar",
    name: "Advisors",
    route: "/advisors",
  },
  {
    icon: "icon-hotel",
    name: "Hotels",
    route: "/hotels",
  },
  {
    icon: "icon-partner",
    name: "Partners",
    route: "/partners",
  },
  {
    icon: "icon-check",
    name: "Checks",
    route: "/checks",
  },
  {
    icon: "icon-speaker",
    name: "Speakers",
    route: "/speakers",
  },{
    icon: "icon-calendar",
    name: "Programs",
    route: "/programs",
  },{
    icon: "icon-partner",
    name: "Exhibitors",
    route: "/exhibitors",
  },
];

function App() {
  return (
    // <AdminLayout>
    <AppContainer>
      {sectionList.map((section: ISections, index: number) => (
        <ItemComponent section={section} key={"admin-sections-" + index} />
      ))}
    </AppContainer>
    // </AdminLayout>
  );
}

type ItemProps = {
  section: ISections;
};

function ItemComponent({ section }: ItemProps) {
  const navigate = useNavigate();

  return (
    <ItemMain
      onClick={() => {
        navigate(section.route!);
      }}
    >
      <ItemIcon className={section.icon}></ItemIcon>
      <ItemName>{section.name}</ItemName>
    </ItemMain>
  );
}

export default App;
