import React, { useState, useCallback, useEffect, ChangeEvent } from "react";
import {
  Container,
  HeaderContainer,
  TableContainer,
  Table,
  TableHeader,
  RowDescription,
  PageTitle,
  NewButton,
  FormContainer,
  FormTitle,
  FormInput,
  FormInputFile,
  FormArea,
  FormImage,
  LabelInput,
  FormLabelContainer,
  FormButton,
  LoaderContainer,
  FormLoaderContainer,
  NotFoundLabel,
} from "./partners.styles";
import { IPartner } from "entities/IPartner";
import {
  deletePartner,
  getPartners,
  postPartner,
  putPartner,
} from "services/partners";
import ModalComponent from "components/modal/modal.component";
import { useForm, SubmitHandler } from "react-hook-form";
import { partnerIcon } from "constants/images";
import Swal from "sweetalert2";
import { colors } from "constants/colors";
import { Grid, ThreeCircles } from "react-loader-spinner";

export default function PartnersPage() {
  const [partnersList, setPartnersList] = useState<IPartner[]>([]);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [actualPartner, setActualPartner] = useState<IPartner | null>();
  const [loader, setLoader] = useState<boolean>(false);

  const getAllPartners = useCallback(async () => {
    try {
      setLoader(true);
      // console.log(loader)
      let partners = await getPartners();
      setLoader(false);
      setPartnersList(partners);
    } catch (error) {
      console.error("SERVER ERROR");
    }
  }, []);

  useEffect(() => {
    getAllPartners();
  }, []);

  return (
    <>
      <Container>
        <HeaderContainer>
          <PageTitle>Partners Console</PageTitle>
          <NewButton
            onClick={() => {
              setActualPartner(null);
              setVisibleModal(true);
            }}
          >
            New +
          </NewButton>
        </HeaderContainer>
        <LoaderContainer visible={loader}>
          <Grid
            height="80"
            width="80"
            color={colors.primaryBlue}
            ariaLabel="grid-loading"
            radius="12.5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={loader}
          />
        </LoaderContainer>
        <NotFoundLabel visible={!loader && !partnersList.length}>
          Not found Partners
        </NotFoundLabel>
        <TableContainer visible={!loader && partnersList.length > 0}>
          <Table>
            <thead>
              <TableHeader>
                <th>Name</th>
              </TableHeader>
            </thead>
            <tbody>
              {partnersList.map((partner: IPartner, index: number) => (
                <PartnerRow
                  key={"partner-row-" + index}
                  partner={partner}
                  onClick={() => {
                    setActualPartner(partner);
                    setVisibleModal(true);
                  }}
                />
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </Container>
      <PartnerModal
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
        actualPartner={actualPartner}
        getAllPartners={getAllPartners}
      />
    </>
  );
}

type RowProps = {
  partner: IPartner;
  onClick?: React.MouseEventHandler<HTMLTableRowElement>;
};

const PartnerRow = ({ partner, onClick }: RowProps) => {
  return (
    <RowDescription onClick={onClick}>
      <td>{partner.partner}</td>
    </RowDescription>
  );
};

type PartnerModalProps = {
  visibleModal: boolean;
  setVisibleModal: React.Dispatch<React.SetStateAction<boolean>>;
  actualPartner?: IPartner | null;
  getAllPartners: () => Promise<void>;
};

function PartnerModal({
  visibleModal,
  setVisibleModal,
  actualPartner,
  getAllPartners,
}: PartnerModalProps) {
  const [actualImg, setActualImg] = useState<string>(partnerIcon);
  const [loader, setLoader] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IPartner>();

  const onSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (e!.target!.files!.length > 0) {
      let urlAux = URL.createObjectURL(e!.target!.files![0]!);

      setActualImg(urlAux);
    }
  };

  const onSubmit: SubmitHandler<IPartner> = async (mesageInfo) => {
    try {
      setLoader(true);
      let formData = new FormData();
      formData.append("partner", mesageInfo!.partner!);
      if (mesageInfo!.img!.length > 0) {
        formData.append("file", mesageInfo!.img![0]);
      } else if (actualPartner) {
        formData.append("img", actualPartner!.img!);
      }

      if (actualPartner) await putPartner(actualPartner.id!, formData);
      else await postPartner(formData);
      setLoader(false);
      getAllPartners();
      setVisibleModal(false);
    } catch (error) {}
  };

  const onDelete = async () => {
    Swal.fire({
      title: "Are you sure about deleting an partner?",
      background: colors.secondaryBlue,
      iconColor: colors.primaryGreen,
      color: colors.primaryWhite,
      confirmButtonColor: colors.primaryGreen,
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        try {
          setLoader(true);
          await deletePartner(actualPartner!.id!);
          setLoader(false);
          getAllPartners();
          setVisibleModal(false);
          Swal.fire("Delete!", "", "success");
        } catch (error) {}
      }
    });
  };

  useEffect(() => {
    if (actualPartner) {
      reset();
      setValue("partner", actualPartner.partner, { shouldValidate: true });
      setActualImg(actualPartner.img!);
    } else {
      setActualImg(partnerIcon);
      reset();
    }
  }, [actualPartner, visibleModal]);

  return (
    <ModalComponent
      show={visibleModal}
      onClose={() => {
        setVisibleModal(false);
      }}
    >
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <FormLoaderContainer visible={loader}>
          <ThreeCircles
            height="100"
            width="100"
            color={colors.primaryGreen}
            wrapperStyle={{}}
            wrapperClass=""
            visible={loader}
            ariaLabel="three-circles-rotating"
          />
        </FormLoaderContainer>
        <FormTitle>
          {actualPartner ? (
            <>
              Update Partner{" "}
              <span
                className="icon-trash"
                onClick={() => {
                  onDelete();
                }}
              ></span>
            </>
          ) : (
            "Create Partner"
          )}
        </FormTitle>
        <FormImage src={actualImg} />
        <FormInputFile
          type="file"
          placeholder="Image"
          {...register("img", {
            onChange: (e) => {
              onSelect(e);
            },
            required: false,
          })}
        />
        <FormLabelContainer>
          <LabelInput size="100%">
            Name - Dimentions ( 1200 x 625 px)
            <FormInput
              type="text"
              placeholder="Name"
              {...register("partner", {
                required: true,
              })}
            />
          </LabelInput>
        </FormLabelContainer>
        <FormButton>Submit</FormButton>
      </FormContainer>
    </ModalComponent>
  );
}
