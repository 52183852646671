import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { GlobalStyle } from "./index.styles";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { loaderRequiresAuth } from "components/auth/requires.auth";
import Root from "routes/root";
import Login from "routes/login";
import "assets/styles/icomoon.css";
import path from "path";
import App from "components/app/app.component";
import AdvisorsPage from "components/advisors/advisors.component";
import HotelsPage from "components/hotels/hotels.component";
import PartnersPage from "components/partners/partners.component";
import ChecksPage from "components/checks/checks.component";
import SpeakersPage from "components/speakers/speakers.component";
import ProgramsPage from "components/programs/programs.component";
import ExhibitorsPage from "components/exhibitors/exhibitors.component";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    loader: async () => {
      return await loaderRequiresAuth(false, "/login");
    },
    children: [
      { path: "/", element: <App /> },
      { path: "/advisors", element: <AdvisorsPage /> },
      { path: "/hotels", element: <HotelsPage /> },
      { path: "/partners", element: <PartnersPage /> },
      { path: "/checks", element: <ChecksPage /> },
      { path: "/speakers", element: <SpeakersPage /> },
      { path: "/programs", element: <ProgramsPage /> },
      { path: "/exhibitors", element: <ExhibitorsPage /> },
    ],
  },
  {
    path: "login",
    element: <Login />,
    loader: async () => {
      return await loaderRequiresAuth(true, "/");
    },
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <GlobalStyle />
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
