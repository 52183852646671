import React, { useState, useCallback, useEffect, ChangeEvent } from "react";
import {
  Container,
  HeaderContainer,
  TableContainer,
  Table,
  TableHeader,
  RowDescription,
  PageTitle,
  NewButton,
  FormContainer,
  FormTitle,
  FormInput,
  FormInputFile,
  FormArea,
  FormImage,
  LabelInput,
  FormLabelContainer,
  FormButton,
  LoaderContainer,
  FormLoaderContainer,
} from "./hotels.styles";
import { IHotel } from "entities/IHotel";
import { deleteHotel, getHotels } from "services/hotels";
import ModalComponent from "components/modal/modal.component";
import { useForm, SubmitHandler } from "react-hook-form";
import { hotelIcon } from "constants/images";
import { postHotel, putHotel } from "services/hotels";
import Swal from "sweetalert2";
import { colors } from "constants/colors";
import { Grid, ThreeCircles } from "react-loader-spinner";

export default function HotelsPage() {
  const [hotelsList, setHotelsList] = useState<IHotel[]>([]);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [actualHotel, setActualHotel] = useState<IHotel | null>();
  const [loader, setLoader] = useState<boolean>(false);

  const getAllHotels = useCallback(async () => {
    try {
      setLoader(true);
      // console.log(loader)
      let hotels = await getHotels();
      setLoader(false);
      setHotelsList(hotels);
    } catch (error) {
      console.error("SERVER ERROR");
    }
  }, []);

  useEffect(() => {
    getAllHotels();
  }, []);

  return (
    <>
      <Container>
        <HeaderContainer>
          <PageTitle>Hotels Console</PageTitle>
          <NewButton
            onClick={() => {
              setActualHotel(null);
              setVisibleModal(true);
            }}
          >
            New +
          </NewButton>
        </HeaderContainer>
        <LoaderContainer visible={loader}>
          <Grid
            height="80"
            width="80"
            color={colors.primaryBlue}
            ariaLabel="grid-loading"
            radius="12.5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={loader}
          />
        </LoaderContainer>
        <TableContainer visible={!loader}>
          <Table>
            <thead>
              <TableHeader>
                <th>Name</th>
                <th>Discount</th>
                <th>Code</th>
                <th>Link</th>
              </TableHeader>
            </thead>
            <tbody>
              {hotelsList.map((hotel: IHotel, index: number) => (
                <HotelRow
                  key={"hotel-row-" + index}
                  hotel={hotel}
                  onClick={() => {
                    setActualHotel(hotel);
                    setVisibleModal(true);
                  }}
                />
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </Container>
      <HotelModal
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
        actualHotel={actualHotel}
        getAllHotels={getAllHotels}
      />
    </>
  );
}

type RowProps = {
  hotel: IHotel;
  onClick?: React.MouseEventHandler<HTMLTableRowElement>;
};

const HotelRow = ({ hotel, onClick }: RowProps) => {
  return (
    <RowDescription onClick={onClick}>
      <td>{hotel.name}</td>
      <td>{hotel.discount}</td>
      <td>{hotel.code}</td>
      <td>{hotel.link}</td>
    </RowDescription>
  );
};

type HotelModalProps = {
  visibleModal: boolean;
  setVisibleModal: React.Dispatch<React.SetStateAction<boolean>>;
  actualHotel?: IHotel | null;
  getAllHotels: () => Promise<void>;
};

function HotelModal({
  visibleModal,
  setVisibleModal,
  actualHotel,
  getAllHotels,
}: HotelModalProps) {
  const [actualImg, setActualImg] = useState<string>(hotelIcon);
  const [loader, setLoader] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IHotel>();

  const onSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (e!.target!.files!.length > 0) {
      let urlAux = URL.createObjectURL(e!.target!.files![0]!);

      setActualImg(urlAux);
    }
  };

  const onSubmit: SubmitHandler<IHotel> = async (mesageInfo) => {
    try {
      setLoader(true);
      let formData = new FormData();
      formData.append("name", mesageInfo!.name!);
      formData.append("discount", mesageInfo!.discount!);
      formData.append("code", mesageInfo!.code!);
      formData.append("link", mesageInfo!.link!);
      if (mesageInfo!.img!.length > 0) {
        formData.append("file", mesageInfo!.img![0]);
      } else if (actualHotel) {
        formData.append("img", actualHotel!.img!);
      }

      if (actualHotel) await putHotel(actualHotel.id!, formData);
      else await postHotel(formData);
      setLoader(false);
      getAllHotels();
      setVisibleModal(false);
    } catch (error) {}
  };

  const onDelete = async () => {
    Swal.fire({
      title: "Are you sure about deleting an hotel?",
      background: colors.secondaryBlue,
      iconColor: colors.primaryGreen,
      color: colors.primaryWhite,
      confirmButtonColor: colors.primaryGreen,
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        try {
          setLoader(true);
          await deleteHotel(actualHotel!.id!);
          setLoader(false);
          getAllHotels();
          setVisibleModal(false);
          Swal.fire("Delete!", "", "success");
        } catch (error) {}
      }
    });
  };

  useEffect(() => {
    if (actualHotel) {
      reset();
      setValue("name", actualHotel.name, { shouldValidate: true });
      setValue("discount", actualHotel.discount, { shouldValidate: true });
      setValue("code", actualHotel.code, { shouldValidate: true });
      setValue("link", actualHotel.link!, { shouldValidate: true });
      setActualImg(actualHotel.img!);
    } else {
      setActualImg(hotelIcon);
      reset();
    }
  }, [actualHotel]);

  return (
    <ModalComponent
      show={visibleModal}
      onClose={() => {
        setVisibleModal(false);
      }}
    >
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <FormLoaderContainer visible={loader}>
          <ThreeCircles
            height="100"
            width="100"
            color={colors.primaryGreen}
            wrapperStyle={{}}
            wrapperClass=""
            visible={loader}
            ariaLabel="three-circles-rotating"
          />
        </FormLoaderContainer>
        <FormTitle>
          {actualHotel ? (
            <>
              Update Hotel{" "}
              <span
                className="icon-trash"
                onClick={() => {
                  onDelete();
                }}
              ></span>
            </>
          ) : (
            "Create Hotel"
          )}
        </FormTitle>
        <FormImage src={actualImg} />
        <FormInputFile
          type="file"
          placeholder="Image"
          {...register("img", {
            onChange: (e) => {
              onSelect(e);
            },
            required: false,
          })}
        />
        <FormLabelContainer>
          <LabelInput size="50%">
            Name
            <FormInput
              type="text"
              placeholder="Name"
              {...register("name", {
                required: true,
              })}
            />
          </LabelInput>
          <LabelInput size="50%">
            Discount
            <FormInput
              type="text"
              placeholder="Discount"
              {...register("discount", {
                required: true,
              })}
            />
          </LabelInput>
          <LabelInput>
            Code
            <FormInput
              type="text"
              placeholder="Code"
              {...register("code", {
                required: true,
              })}
            />
          </LabelInput>
          <LabelInput>
            Url
            <FormInput
              type="text"
              placeholder="Url"
              {...register("link", {
                required: true,
              })}
            />
          </LabelInput>
        </FormLabelContainer>
        <FormButton>Submit</FormButton>
      </FormContainer>
    </ModalComponent>
  );
}
