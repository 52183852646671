import React, { useRef, useEffect, useState } from "react";
import {
  ModalBackground,
  ModalCloseArea,
  ModalContainer,
} from "./modal.styles";

function usePrevious(value: boolean) {
  const ref = useRef<boolean>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

const Status = {
  Hidden: "Hidden",
  Entering: "Entering",
  Entered: "Entered",
  Exiting: "Exiting",
};

type ModalProps = {
  children: React.ReactNode;
  onClose?: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
  show: boolean;
};

export default function ModalComponent({
  children,
  show,
  onClose,
}: ModalProps) {
  const [visibilityStatus, setVisibilityStatus] = useState<String>(
    Status.Hidden
  );
  const [opacityStatus, setOpacityStatus] = useState<number>(0);

  const previousShow = usePrevious(show);

  useEffect(() => {
    if (show) {
      setVisibilityStatus(Status.Entering);
    }
    if (previousShow && !show) {
      setVisibilityStatus(Status.Exiting);
      setOpacityStatus(0);
    }
  }, [previousShow, show]);

  useEffect(() => {
    if (visibilityStatus === Status.Entering) {
      setTimeout(() => {
        setOpacityStatus(1);
      }, 8);
    }
  }, [visibilityStatus]);

  const handleTransitionEnd = () => {
    if (visibilityStatus === Status.Entering) {
      setVisibilityStatus(Status.Entered);
      setOpacityStatus(1);
    }

    if (visibilityStatus === Status.Exiting) {
      setTimeout(() => {
        setVisibilityStatus(Status.Hidden);
      }, 250);
    }
  };

  return visibilityStatus !== Status.Hidden ? (
    <ModalBackground
      opacity={opacityStatus}
      onTransitionEnd={handleTransitionEnd}
    >
      <ModalContainer>{children}</ModalContainer>
      <ModalCloseArea onClick={onClose}></ModalCloseArea>
    </ModalBackground>
  ) : null;
}
