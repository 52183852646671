import axios, { AxiosRequestConfig } from "axios";
import { ICheck } from "entities/ICheck";

const baseURL: string = process.env.REACT_APP_API_URL ?? "";

const reqConfig: AxiosRequestConfig = {
  baseURL,
};

export const getChecks = async (): Promise<ICheck[]> => {
  const resp = await axios.get<ICheck[]>("/checks", {
    ...reqConfig,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return resp.data;
};

export const postCheck = async (req: FormData) => {
  const resp = await axios.post("/checks", req, reqConfig);
  return resp.data;
};

export const putCheck = async (checkID: string, req: FormData) => {
  const resp = await axios.put(`/checks/${checkID}`, req, reqConfig);
  return resp.data;
};

export const deleteCheck = async (checkID: string): Promise<any> => {
  const resp = await axios.delete(`/checks/${checkID}`, reqConfig);
  return resp.data;
};
