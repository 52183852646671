import { colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div``;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
`;

export const PageTitle = styled.h1`
  color: ${colors.primaryBlue};
`;

export const NewButton = styled.button`
  outline: none;
  border: none;
  background-color: ${colors.primaryGreen};
  color: ${colors.primaryBlue};
  font-size: 1.2em;
  font-weight: bold;
  padding: 8px 15px;
  border-radius: 9px;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    transform: scale(1.1);
  }
`;

type LoaderTableProps = {
  visible: boolean;
};

export const LoaderContainer = styled.div<LoaderTableProps>`
  display: ${(props) => (props.visible ? "flex" : "none")};
  height: 200px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const TableContainer = styled.div<LoaderTableProps>`
  display: ${(props) => (props.visible ? "block" : "none")};
  padding: 0px 50px;

  table,
  th,
  td {
    border: 1px solid ${colors.primaryBlue};
    border-collapse: collapse;
  }
  padding-bottom: 50px;
  overflow-y: scroll;
  height: 700px;
`;

export const Table = styled.table``;

export const TableHeader = styled.tr`
  background-color: ${colors.primaryGreen};
`;

export const RowDescription = styled.tr`
  cursor: pointer;
  &:hover {
    background-color: ${colors.primaryGrey};
  }
`;

export const FormLoaderContainer = styled.form<LoaderTableProps>`
  display: ${(props) => (props.visible ? "flex" : "none")};
  position: absolute;
  top: -20px;
  bottom: 0px;
  left: -8px;
  right: -8px;
  background-color: ${colors.opacityBlue};
  border-radius: 10px;
  z-index: 2;
  align-items: center;
  justify-content: center;
`;

export const FormContainer = styled.form`
  text-align: center;
  position: relative;
`;

export const FormTitle = styled.h1`
  color: ${colors.primaryBlue};
  position: relative;

  span {
    position: absolute;
    right: 10px;
    cursor: pointer;
    color: ${colors.primaryGreen};

    &:hover {
      color: ${colors.primaryBlue};
    }
  }
`;

export const FormImage = styled.img`
  width: 250px;
  height: 150px;
  border-radius: 20px;
  object-fit: cover;
  background: ${colors.primaryGrey};
  border: 2px solid ${colors.primaryGreen};
`;

// border-radius: 10px;
// border: 1px solid ${colors.primaryBlue};
export const FormInputFile = styled.input`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 350px;
  max-width: 100%;
  color: #444;
  padding: 5px;
  background: ${colors.primaryWhite};
  margin-top: 10px;
  margin-bottom: 10px;

  &::file-selector-button {
    margin-right: 20px;
    border: none;
    background: ${colors.primaryGreen};
    padding: 10px 20px;
    border-radius: 10px;
    color: ${colors.primaryBlue};
    font-weight: bold;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
  }

  &::file-selector-button:hover {
    background: #0d45a5;
  }
`;

export const FormLabelContainer = styled.div`
  width: 600px;
  display: flex;
  flex-wrap: wrap;
`;

type LabelProps = {
  size?: string;
};

export const LabelInput = styled.label<LabelProps>`
  width: ${(props) => (props.size ? props.size : "100%")};
  padding-left: 5px;
  padding-right: 5px;
  display: block;
  text-align: left;
  color: ${colors.primaryBlue};
  font-weight: bold;
  font-size: 1em;
  margin-top: 10px;

  input,
  textarea {
    margin-top: 5px;
    display: block;
    width: 100%;
  }
`;

export const FormInput = styled.input`
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid ${colors.primaryGreen};
`;

export const FormArea = styled.textarea`
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid ${colors.primaryGreen};
`;

export const FormButton = styled.button`
  margin-top: 10px;
  margin-bottom: 20px;
  bmargin-right: 20px;
  border: none;
  border: 1px solid ${colors.primaryGreen};
  background: ${colors.primaryGreen};
  padding: 10px 20px;
  border-radius: 10px;
  color: ${colors.primaryBlue};
  font-weight: bold;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  &:hover {
    border: 1px solid ${colors.primaryGreen};
    background-color: transparent;
    color: ${colors.primaryGreen};
  }
`;
