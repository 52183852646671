import axios, { AxiosRequestConfig } from "axios";
import { IExhibitor } from "entities/IExhibitor";

const baseURL: string = process.env.REACT_APP_API_URL ?? "";

const reqConfig: AxiosRequestConfig = {
  baseURL,
};

export const getExhibitors = async (): Promise<IExhibitor[]> => {
  const resp = await axios.get<IExhibitor[]>("/exhibitors", {
    ...reqConfig,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return resp.data;
};

export const postExhibitor = async (req: FormData) => {
  const resp = await axios.post("/exhibitors", req, reqConfig);
  return resp.data;
};

export const putExhibitor = async (exhibitorID: string, req: FormData) => {
  const resp = await axios.put(`/exhibitors/${exhibitorID}`, req, reqConfig);
  return resp.data;
};

export const deleteExhibitor = async (exhibitorID: string): Promise<any> => {
  const resp = await axios.delete(`/exhibitors/${exhibitorID}`, reqConfig);
  return resp.data;
};