import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  FormContainer,
  LoginButton,
  LoginContainer,
  LoginInput,
  LoginTitle,
  LogoContainer,
} from "./login.styles";
import { logo } from "constants/images";
import { IDashboardUser } from "entities/IDashboardUser";
import Swal from "sweetalert2";
import { colors } from "constants/colors";
import { validateCredentials } from "services/login";
import { useNavigate } from "react-router-dom";

function LoginComponent() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IDashboardUser>();

  const onSubmit: SubmitHandler<IDashboardUser> = async (mesageInfo) => {
    // console.log(mesageInfo);
    try {
      // setUploadInProgress(true);
      // await sendEmail(mesageInfo);
      await validateCredentials(mesageInfo);

      // Swal.fire({
      //   icon: "success",
      //   title: "Email sent",
      //   text: "Login Success",
      //   background: colors.secondaryBlue,
      //   iconColor: colors.primaryGreen,
      //   color: colors.primaryWhite,
      //   confirmButtonColor: colors.primaryGreen,
      // });
      navigate("/");
      // setUploadInProgress(false);
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: "Error",
        text: "Incorrect credentials",
        background: colors.secondaryBlue,
        iconColor: colors.primaryGreen,
        color: colors.primaryWhite,
        confirmButtonColor: colors.primaryGreen,
      });
      // setUploadInProgress(false);
    }
  };

  return (
    <LoginContainer>
      <LogoContainer src={logo} />
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <LoginTitle>LOGIN</LoginTitle>
        <LoginInput
          type="text"
          placeholder="USER"
          {...register("userName", {
            required: true,
          })}
        />
        <LoginInput
          type="password"
          placeholder="PASSWORD"
          {...register("passKey", {
            required: true,
          })}
        />
        <LoginButton>Submit</LoginButton>
      </FormContainer>
    </LoginContainer>
  );
}

export default LoginComponent;
